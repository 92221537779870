
//==========  Extends  ==========//

// use these classes and placeholders with @extends
// classes defined here will be output in the final compiled css
// placeholders will not and are only used within sass
 
// note - extends will not work within media queries


//==========  Helpers and resets  ==========//

// set on .inner containers
%full-width { 
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

// clear floats on elements after container
// prevents elements after from wrapping
%clear-fix {
    
    &:after {
        content: '';
        clear: both;
        display: block;
        width: 100%;
        overflow: hidden;
    }
}

// set child elements to be an automatic equal height
// must be set on parent container (not an ancestor!)
// %clear-fix should be removed (creates extra columns)
// flexbox progressive enhancement
%elastic {
    display: block;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    overflow: hidden;
}

// svg icon reset - use to hide any icons
%no-icon {
    min-height: 0;

    .icon-link {
        min-height: 0;
        padding-left: 0;
    }

    .icon {
        display: none;
    }
}

// reset for images, prevents whitespace around image
%image {
    display: block;
    width: 100%;
}

// default style for blockquotes
%blockquote {
    margin: 0;
    
    // left quote marks
    &:before {
        content: '\201C';
    }
    
    // right quote marks
    &:after {
        content: '\201D';
    }
    
    // reset for p tags that are put in by the CMS editor
    p {
        display: inline;
        margin: 0;
    }
}

// add as class to element or extend
.hidden {
    display: none;
}

// loading animation styles
// set based styles for dots
// configure dot styles with mixin
%loading {
    @include centre(absolute);
    @include loading();

    p {
        margin: 0;
        text-align: center;
    }

    .dot {
        display: inline-block;
        transform: scale(0);
        border-radius: 50%;
        animation: loading 1s ease infinite;
        
        &:nth-child(2) {
            animation: loading 1s ease infinite 0.3s;
        }
        
        &:nth-child(3) {
            animation: loading 1s ease infinite 0.6s;
        }
    }
}

// assign the default to a global class
.loading {
    @extend %loading;
}

// Uppercase Text styles
%uppercase-text {
    text-transform: uppercase;
    letter-spacing: 1px;
}

%hover-animation {

    &:after {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 50%;
        right: 50%;
        height: 2px;
        background: $color-primary;
        width: 0%;
        transition: all t(link) ease-out;
    }

    &:hover {

        &:after {
            left: 0%;
            right: 0%;
            width: 100%;
        }
    }
}


%scroll-animation {

    .mouse-icon {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid $color-white;
        border-radius: 25px;
        opacity: 0.75;
        box-sizing: content-box;

        .scroller {
            width: 3px;
            height: 10px;
            border-radius: 25%;
            background-color: $color-white;
            animation-name: scroll;
            animation-duration: 2.2s;
            animation-timing-function: cubic-bezier(.15,.41,.69,.94);
            animation-iteration-count: infinite;
        }

        @keyframes scroll {
          0% { opacity: 0; }
          10% { transform: translateY(0); opacity: 1; }
          100% { transform: translateY(15px); opacity: 0;}
        }
    }
}

%staff-titles {
    @extend %uppercase-text;
    font-weight: $font-bold;
    color: $color-text;
    font-size: $font-size-body - 0.2rem;
    margin-bottom: $block-margin / 2;
}

%specialism-hover {

    &:after {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 0%;
        right: 100%;
        height: 2px;
        background: $color-primary;
        width: 10%;
        transition: all t(link) ease-out;
    }

     &:before {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 0;
        right: 0;
        height: 2px;
        background: $specialism-border;
        width: 100%;
        transition: all t(link) ease-out;
    }

    &:hover {

        &:after {
            left: 0%;
            right: 0%;
            width: 100%;
        }
    }
}
